<template>
  <vCard :col="12" header="Autogegevens" fluid>
    <table class="vuetable ui celled table table-padding fixed">
      <tbody>
      <tr>
        <td></td>
        <td>
          <span>Huidig</span>
        </td>
        <td>
          <span>Wincar / VVI</span>
        </td>
      </tr>
      <!-- Start Option -->
      <tr>
        <td>
          <span class="bold">Merk</span><br/>
          <span class="bold">Model</span>
        </td>
        <td>
          <span>{{ vehicle.make.name }}</span><br/>
          <span>{{ vehicle.model.name }}</span>
        </td>
        <td>
          <selectVehicleModel @submit="setVehicleModel" :hide-labels="true"/>
        </td>
      </tr>
      <!-- End Option -->
      <!-- Start Option -->
      <tr>
        <td>
          <span class="bold">Type</span>
        </td>
        <td>
          <span>{{ vehicle.type }}</span>
        </td>
        <td>
          <vInput placeHolderText="type" v-model="form.type"/>
        </td>
      </tr>
      <!-- End Option -->
      <!-- Start Option -->
      <tr>
        <td>
          <span class="bold">Kleur</span>
        </td>
        <td>
          <span>{{ vehicle.color.name }}</span>
        </td>
        <td>
          <v-select :options="colors"
                    v-model="form.color"
                    placeHolderText="Kleur"
                    optionsKey="id"
                    optionsValue="name"
                    :disabled="false"/>
        </td>
      </tr>
      <!-- End Option -->
      <!-- Start Option -->
      <tr>
        <td>
          <span class="bold">Brandstof</span>
        </td>
        <td>
          <span>{{ vehicle.fuelType.name }}</span>
        </td>
        <td>
          <v-select :options="fuels"
                    v-model="form.fuelType"
                    placeHolderText="Brandstof"
                    optionsKey="id"
                    optionsValue="name"
                    :disabled="false"/>
        </td>
      </tr>
      <!-- End Option -->
      <!-- Start Option -->
      <tr>
        <td>
          <span class="bold">Aantal zitplaatsen</span>
        </td>
        <td>
          <span>{{ vehicle.seats }}</span>
        </td>
        <td>
          <vInput placeHolderText="Zitplaatsen"
                  v-model="form.amountOfSeats"/>
        </td>
      </tr>
      <!-- End Option -->
      <!-- Start Option -->
      <tr>
        <td>
          <span class="bold">Aantal deuren</span>
        </td>
        <td>
          <span>{{ vehicle.doors }}</span>
        </td>
        <td>
          <vInput placeHolderText="Deuren"
                  v-model="form.amountOfDoors"/>
        </td>
      </tr>
      <!-- End Option -->
      <!-- Start Option -->
      <tr>
        <td>
          <span class="bold">Is Automaat</span>
        </td>
        <td>
          <span>{{ vehicle.has_automatic_transmission }}</span>
        </td>
        <td>
          <v-checkbox v-model="vehicle.transmission"
                      :disabled="false"/>
        </td>
      </tr>
      <!-- End Option -->
      <!-- Start Option -->
      <tr>
        <td>
          <span class="bold">Aantal versnellingen</span>
        </td>
        <td>
          <span>5</span>
        </td>
        <td>
          <vInput placeHolderText="Versnellingen" v-model="form.amountOfGears"/>
        </td>
      </tr>
      <!-- End Option -->
      <!-- Start Option -->
      <tr>
        <td>
          <span class="bold">Bruto BPM</span>
        </td>
        <td>
          <span>{{ vehicle.bpm | currency }}</span>
        </td>
        <td>
          <vInput placeHolderText="BPM" v-model="form.bpm"/>
        </td>
      </tr>
      <!-- End Option -->
      <!-- Start Option -->
      <tr>
        <td>
          <span class="bold">Carrosserie</span>
        </td>
        <td>
          <span>{{ vehicle.body_style.name }}</span>
        </td>
        <td>
          <v-select :options="bodyStyles"
                    v-model="form.bodyStyle"
                    placeHolderText="Carrosserie"
                    optionsKey="id"
                    optionsValue="name"
                    :disabled="false"/>
        </td>
      </tr>
      <!-- End Option -->
      </tbody>
    </table>
    <!--    <div class="row">-->
    <div class="col-md-12">
      <button class="btn btn-primary pull-right">Opslaan</button>
      <p></p>
    </div>
    <pre>{{ form.color }}</pre>
    <pre>{{ getVehicleColor }}</pre>
    <!--    </div>-->
  </vCard>
</template>


<script>
export default {
  name: 'purchaseList',
  components: {
    selectVehicleModel: () => import('@/components/forms/selectVehicleModel'),
  },
  created() {
    this.getVehicles()
    this.getFuels()
    this.getBodyStyles()
    this.getColors()
  },
  props: {
    vehicle: {
      required: true,
      type: Object
    }
  },
  computed: {
    getVehicleColor: {
      get() {
        return this.colors.filter(e => {
          return e.name === this.vehicle.color.name
        })[0]
      }
    }
  },
  watch: {
    getVehicleColor: {
      handler(newV) {
        this.form.color.value = newV
        alert('update')
      }
    }
  },
  data() {
    return {
      fuels: [],
      bodyStyles: [],
      colors: [],
      form: {
        // model: { value: this.vehicle.model.name },
        amountOfDoors: {value: this.vehicle.doors},
        // amountOfGears: {value: this.vehicle.type},
        amountOfSeats: {value: this.vehicle.seats},
        bpm: {value: this.vehicle.bpm},
        bodyStyle: {value: this.vehicle.type},
        color: {value: null},
        fuelType: {value: this.vehicle.type},
        transmission: {value: this.vehicle.type},
        type: {value: this.vehicle.type},
      }
    }
  },
  methods: {
    async getVehicles() {
      let {data} = await this.$api.purchase.list()
    },
    async getFuels() {
      let {data} = await this.$api.fuel.list()
      data.sort((a, b) => (a.name > b.name) ? 1 : -1)
      this.fuels = data

    },
    async getBodyStyles() {
      let {data} = await this.$api.bodyStyles.list()
      data.sort((a, b) => (a.name > b.name) ? 1 : -1)
      this.bodyStyles = data
    },
    async getColors() {
      let {data} = await this.$api.color.list()
      data.sort((a, b) => (a.name > b.name) ? 1 : -1)
      this.colors = data
    },
    setVehicleModel(e) {
      this.form.model = e
    }
  }
}
</script>
